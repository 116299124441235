import { useMemo } from "react";
import dataService from "../services/data";
import { EnumItem, EnumEntity } from "interfaces";
import { Select } from "semantic-ui-react";
interface EnumProps {
  value: string | number | string[] | number[];
  enumName: string;
  multiple?: boolean;
  dataType?: "string" | "number" | "boolean" | "array" | "object";
  onChange?: (val: string | number | string[] | number[]) => void;
}
function Enum({
  value,
  enumName,
  multiple,
  onChange,
}: EnumProps): React.ReactElement {
  const enumInfo = useMemo((): EnumEntity => {
    let rs: EnumEntity = dataService.getEnum(enumName);
    return rs;
  }, [enumName]);
  if (!enumInfo) {
    return <p>Enum not found</p>;
  }
  return (
    <div className="ui fluid input">
      <Select
        fluid
        multiple={multiple}
        value={value}
        options={enumInfo.items.map((i) => {
          return { text: i.label, value: i.value };
        })}
        onChange={(evt: any, val: any) => {
          onChange(val.value);
        }}
      />
    </div>
  );
}
export default Enum;
