import { DatePicker, Enum, Loading } from "components";
import dayjs from "dayjs";
import _ from "lodash";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import ReactToPrint from "react-to-print";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Button, Card, Form, Select, Table } from "semantic-ui-react";
import { api, ui } from "services";
// @ts-ignore
import { Bar, Line, Pie } from "react-chartjs-2";
import Config from "config";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);
export default function ReportDevice() {
  const [loading, setLoading] = useState<boolean>(true);
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  let years: number[] = [];
  for (var i = 2020; i <= dayjs().year(); i++) {
    years.push(i);
  }
  const [input, setInput] = useState<any>({});
  const [devices, setDevices] = useState<any[]>([]);
  const [report, setReport] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [options, setOptions] = useState<any>(null);
  const [deviceInfos, setDeviceInfos] = useState<any[]>([]);
  const [teamInfos, setTeamInfos] = useState<any[]>([]);
  const [dataTotal, setDataTotal] = useState<any>(null);
  const [optionsTotal, setOptionsTotal] = useState<any>(null);
  const chartRef = useRef();
  function handleInput(name: string, value: any) {
    console.log("handleinput", { name, value });
    let tmp = _.cloneDeep(input);
    tmp[name] = value;
    setInput(tmp);
  }
  useEffect(() => {
    async function loadData() {
      let devices = await api.post("/api/get-devices", {});
      setDevices(devices.data);
    }
    loadData();
  }, []);
  async function loadReport() {
    if (!(input.month && input.year)) {
      return ui.alert("Vui lòng nhập ngày vi phạm");
    }
    let rs = await api.post("/report/report-device", {
      type: Config.DeviceType,
      start_date: dayjs()
        .year(input.year)
        .month(input.month - 1)
        .date(1)
        .startOf("month")
        .toDate(),
      end_date: dayjs()
        .year(input.year)
        .month(input.month - 1)
        .date(1)
        .endOf("month")
        .toDate(),
    });
    let labels = rs.map((i: any) => i.date);
    let datasets = [];
    const data = {
      labels,
      datasets: [
        {
          label: "Số lượng vi phạm chưa kiểm duyệt",
          data: labels.map((a: string, index: number) => rs[index].review),
          backgroundColor: "rgb(255, 99, 132)",
        },
        {
          label: "Số lượng vi phạm đã kiểm duyệt",
          data: labels.map(
            (a: string, index: number) => rs[index].total - rs[index].review
          ),
          backgroundColor: "rgb(75, 192, 192)",
        },
      ],
    };
    setData(data);
    setOptions({
      plugins: {
        title: {
          display: true,
          text: `THỐNG KÊ VI PHẠM THEO THIẾT BỊ: THÁNG ${input.month}/${input.year}`,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    });
    setOptionsTotal({
      plugins: {
        title: {
          display: true,
          text: `THỐNG KÊ VI PHẠM THEO TRONG THÁNG ${input.month}/${input.year}`,
        },
      },
      responsive: true,
    });
    let dataTotal = {
      labels,
      datasets: [
        {
          label: "Số lượng vi phạm",
          data: labels.map((a: string, index: number) => rs[index].total),
          backgroundColor: "rgb(255, 99, 132)",
        },
      ],
    };
    setDataTotal(dataTotal);
  }

  function filter() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <div className="flex justify-between">
              <p>Tìm kiếm</p>
            </div>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Form inline>
            <Form.Group>
              <Form.Field>
                <label>Tháng</label>
                <Select
                  options={months.map((d: any) => {
                    return { text: `Tháng ${d}`, value: d };
                  })}
                  onChange={(evt: any, val: any) => {
                    handleInput("month", val.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Năm</label>
                <Select
                  options={years.map((d: any) => {
                    return { text: d, value: d };
                  })}
                  onChange={(evt: any, val: any) => {
                    handleInput("year", val.value);
                  }}
                />
              </Form.Field>

              <Button
                content="Thống kê"
                color="blue"
                icon="chart bar"
                onClick={() => {
                  loadReport();
                }}
              />
              <Button
                content="Làm mới"
                color="teal"
                icon="refresh"
                onClick={() => {
                  loadReport();
                }}
              />
            </Form.Group>
          </Form>
        </Card.Content>
      </Card>
    );
  }
  function content() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <div className="flex justify-between">
              <p>Kết quả thống kê</p>
              <ReactToPrint
                trigger={() => (
                  <Button icon="print" content="In báo cáo" color="teal" />
                )}
                content={() => chartRef?.current}
              />
            </div>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          {data && dataTotal && (
            <Chart
              month={input.month}
              year={input.year}
              ref={chartRef}
              options={options}
              data={data}
              optionTotal={optionsTotal}
              dataTotal={dataTotal}
            />
          )}
        </Card.Content>
      </Card>
    );
  }

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          <div className="flex justify-between">
            <p>Thống kê tổng hợp</p>
          </div>
        </Card.Header>
      </Card.Content>
      <Card.Content>
        {filter()}
        {content()}
      </Card.Content>
    </Card>
  );
}
const Chart = forwardRef((props: any, ref: any) => {
  return (
    <div ref={ref} className="p-4">
      <p className="font-bold">
        Xuất báo cáo tháng {props.month}/{props.year}
      </p>
      <p className="font-bold">
        Thiết bị:{" "}
        {Config.DeviceType === 0
          ? "THIẾT BỊ ĐO NỒNG ĐỘ CỒN"
          : "THIẾT BỊ ĐO TỐC ĐỘ"}
      </p>
      <div className="flex gap-6 w-full flex-wrap">
        <div className="w-[700px]">
          <Bar options={props.options} data={props.data} height={200} />
        </div>
        <div className="w-[700px]">
          <Line
            options={props.optionsTotal}
            data={props.dataTotal}
            height="200"
            width={400}
          />
        </div>
      </div>
    </div>
  );
});
