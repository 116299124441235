import { Loading, Schema } from "components";
import { useEffect, useRef, useState } from "react";
import { Button, Card } from "semantic-ui-react";
import { api, data, ui } from "services";

export default function TeamInfo() {
  const formInfo = data.getFormByName("team");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const mainSchemaRef = useRef();
  useEffect(() => {
    async function loadProfile() {
      try {
        setLoading(true);
        let rs = await api.post("/api/get-profile", {});
        setUser(rs.data[0]);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    loadProfile();
  }, []);
  async function save() {
    try {
      setLoading(true);
      let rs = await api.post("/user/update-user-profile", user);
      ui.alert("Cập nhật thành công");
      setUser(rs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          <div className="flex justify-between">
            <p>Thông tin đơn vị</p>
            <Button
              onClick={() => {
                save();
              }}
              loading={loading}
              content="Lưu thông tin"
              primary
              icon="save"
              labelPosition="left"
            />
          </div>
        </Card.Header>
      </Card.Content>
      <Card.Content>
        {loading ? (
          <Loading />
        ) : (
          <Schema
            ref={mainSchemaRef}
            controls={formInfo.controls}
            value={user || {}}
            onChange={(val: any) => {
              setUser(val);
            }}
          />
        )}
      </Card.Content>
    </Card>
  );
}
