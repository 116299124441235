import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form } from "semantic-ui-react";
import { api, ui } from "services";

export default function ChangePassword() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = Number(query.get("id"));
  const [pass, setPass] = useState<string>("");
  async function changePassword() {
    if (!pass) {
      return ui.alert("Chưa nhập password");
    }
    await ui.confirm("Bạn có chắc muốn đổi mật khẩu không?");
    await api.post("/user/change-password", {
      user_id: id,
      password: pass,
    });
    ui.alert("Đổi mật khẩu thành công");
  }
  return (
    <div>
      <div className="flex justify-between border-none h-full">
        <div className="flex">
          <p className="bg-white p-2 font-bold text-[#776d63] h-[34px]">
            Đổi mật khẩu
          </p>
          <div className="triangle-bottomleft-white"></div>
        </div>
      </div>
      <div className="bg-white p-2">
        <div className="flex justify-between py-2 items-center">
          <Form
            onSubmit={() => {
              changePassword();
            }}
          >
            <Form.Field>
              <label>Mật khẩu mới</label>
              <Form.Input
                type="password"
                value={pass}
                onChange={(evt: any) => {
                  setPass(evt.target.value);
                }}
              />
            </Form.Field>
            <Form.Field>
              <Button icon="lock" content="Đổi mật khẩu" color="blue" />
            </Form.Field>
          </Form>
        </div>
      </div>
    </div>
  );
}
