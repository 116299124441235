import { DatePicker, Entity, Enum, Loading, ViewEnum } from "components";
import Config from "config";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Card, Form, Select, Table } from "semantic-ui-react";
import { api, ui } from "services";

export default function ReportDashboard() {
  const [loading, setLoading] = useState<boolean>(true);
  const [input, setInput] = useState<any>({});
  const [devices, setDevices] = useState<any[]>([]);
  const [report, setReport] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [deviceInfos, setDeviceInfos] = useState<any[]>([]);
  const [searchTeam, setSearchTeam] = useState<any>();
  const [teamInfos, setTeamInfos] = useState<any[]>([]);
  function handleInput(name: string, value: any) {
    let tmp = _.cloneDeep(input);
    tmp[name] = value;
    setInput(tmp);
  }
  useEffect(() => {
    async function loadData() {
      let devices = await api.post("/api/get-devices", {});
      setDevices(devices.data);
    }
    loadData();
  }, []);
  async function loadReport() {
    if (!(input.start_date && input.end_date)) {
      return ui.alert("Vui lòng nhập ngày vi phạm");
    }
    input.type = Config.DeviceType;
    let rs = await api.post("/report/report-dashboard", input);
    let totalViolate = 0,
      totalReview = 0,
      totalNoti = 0,
      totalInline = 0;
    rs.report.forEach((i: any) => {
      totalViolate += Number(i.total);
      totalReview += Number(i.review);
      totalNoti += Number(i.noti);
      totalInline += Number(i.inline);
    });
    setData({ totalViolate, totalReview, totalNoti, totalInline });
    setTeamInfos(rs.teamInfos);
    setDeviceInfos(rs.deviceInfos);
    setReport(rs.report);
  }
  function filter() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <div className="flex justify-between">
              <p>Tìm kiếm</p>
            </div>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Form inline>
            <Form.Group>
              <Form.Field>
                <label>Thời gian VP từ ngày</label>
                <DatePicker
                  selected={input.start_date}
                  onChange={(val: Date) => {
                    handleInput(
                      "start_date",
                      dayjs(val).startOf("day").toDate()
                    );
                  }}
                  dateFormat="dd/MM/yyyy"
                />
              </Form.Field>
              <Form.Field>
                <label>Thời gian VP đến ngày</label>
                <DatePicker
                  selected={input.end_date}
                  onChange={(val: Date) => {
                    handleInput("end_date", dayjs(val).endOf("day").toDate());
                  }}
                  dateFormat="dd/MM/yyyy"
                />
              </Form.Field>
              <Form.Field>
                <label>Thiết bị phát hiện</label>
                <Select
                  options={devices.map((d: any) => {
                    return { text: `${d.name} [${d.id}]`, value: d.id };
                  })}
                  onChange={(evt: any, val: any) => {
                    handleInput("device_id", val.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Đơn vị sử dụng</label>
                <Entity
                  value={searchTeam}
                  gridName={"team"}
                  multiple={false}
                  onChange={(val: any) => {
                    handleInput("user_team_id", val);
                  }}
                />
              </Form.Field>
              <Button
                content="Thống kê"
                color="blue"
                icon="chart bar"
                onClick={() => {
                  loadReport();
                }}
              />
              <Button
                content="Làm mới"
                color="teal"
                icon="refresh"
                onClick={() => {
                  loadReport();
                }}
              />
            </Form.Group>
          </Form>
        </Card.Content>
      </Card>
    );
  }
  function content() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <div className="flex justify-between">
              <p>Dữ liệu</p>
            </div>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          {report && data && (
            <div>
              <p>
                <b>
                  Hệ thống phát hiện:
                  <span className="text-red-600">{data.totalViolate}</span> vi
                  phạm. Đang kiểm duyệt{" "}
                  <span className="text-red-600">{data.totalReview}</span>; Đã
                  kiểm duyệt{" "}
                  <span className="text-red-600">
                    {data.totalViolate - data.totalReview}
                  </span>{" "}
                  (Gửi thông báo:{" "}
                  <span className="text-red-600">{data.totalNoti}</span>; Xử lý
                  ngoài hiện trường{" "}
                  <span className="text-red-600">{data.totalInline}</span>)
                </b>
              </p>
            </div>
          )}
          <Table celled structured>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell rowSpan="2">TT</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">
                  Thiết bị phát hiện vi phạm
                </Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Đơn vị sử dụng</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Tổng số vi phạm</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Đang kiểm duyệt</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Đã kiểm duyệt</Table.HeaderCell>
                <Table.HeaderCell colSpan="2">Đang kiểm duyệt</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Gửi thông báo</Table.HeaderCell>
                <Table.HeaderCell>Xử lý ngoài hiện trường</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {report &&
                report.map((r: any, index: number) => (
                  <Table.Row>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>
                      <ViewEnum enumName={"device_type"} value={r.type} />
                    </Table.Cell>
                    <Table.Cell>
                      {
                        teamInfos.find((i: any) => i.id === r.user_team_id)
                          ?.name
                      }
                    </Table.Cell>
                    <Table.Cell>{r.total}</Table.Cell>
                    <Table.Cell>{r.review}</Table.Cell>
                    <Table.Cell>{r.total - Number(r.review)}</Table.Cell>
                    <Table.Cell>{r.noti}</Table.Cell>
                    <Table.Cell>{r.inline}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          <div className="flex justify-between">
            <p>Thống kê tổng hợp</p>
          </div>
        </Card.Header>
      </Card.Content>
      <Card.Content>
        {filter()}
        {content()}
      </Card.Content>
    </Card>
  );
}
