import { Dropdown, Image, Menu } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { Link, useNavigate } from "react-router-dom";
import { api, ui } from "services";
import { Language } from "components";
import { useTranslation } from "react-i18next";
import Config from "config";
import { useState } from "react";
export default function Header({ toggleMenu }: { toggleMenu: Function }) {
  const { t } = useTranslation();
  const user: any = useSelector((state: RootState) => state.user);
  const [device, setDevice] = useState(Number(api.getDeviceType()));
  const nav = useNavigate();
  async function onLogoutClick() {
    await ui.confirm(t("Are you sure want to logout?"));
    localStorage.clear();
    nav("/login");
  }
  function changeDevice(id: number) {
    localStorage.setItem("device_type", String(id));
    setDevice(id);
    window.location.reload();
  }
  function deviceName() {
    return device === 0 ? "THIẾT BỊ ĐO TỐC ĐỘ" : "THIẾT BỊ ĐO NỒNG ĐỘ CỒN";
  }
  function renderDeviceInfo() {
    switch (Config.DeviceType) {
      case -1:
        return (
          <Dropdown
            trigger={<span className="font-bold">{deviceName()} </span>}
            direction="left"
            pointing
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="tachometer alternate"
                text={"Thiết bị đo tốc độ"}
                onClick={() => {
                  changeDevice(0);
                }}
              />
              <Dropdown.Item
                icon="thermometer quarter"
                text={"Thiết bị đo nồng độ cồn"}
                onClick={() => {
                  changeDevice(1);
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
        );
      default:
        return <p>{deviceName()}</p>;
    }
  }

  return (
    <div className="fixed top-0 z-[100] w-full bg-[#392272] h-[68px]">
      <div className="w-full flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/" className="header-yellow h-[68px]">
            <div className="w-[197px] header-yellow">
              <div className="font-bold p-4">
                <div className="flex items-center gap-2">
                  <img src="logo.png" className="h-6" />
                  <p>BỘ CÔNG AN</p>
                </div>
                <p className="text-[10px]">CỤC CẢNH SÁT GIAO THÔNG</p>
              </div>
            </div>
          </Link>
          <div
            className="header-yellow h-[68px] cursor-pointer"
            onClick={() => {
              toggleMenu();
            }}
          >
            <div className="p-4">
              <i className="icon bars" />
            </div>
          </div>
          <div
            className="h-full"
            onClick={() => {
              toggleMenu();
            }}
          >
            <div className="bg-[#392272] font-bold">
              <div className="flex">
                <div className="triangle-bottomleft"></div>
                <p className="text-white p-2 bg-[#392272]">
                  HỆ THỐNG QUẢN LÝ THIẾT BỊ KỸ THUẬT NGHIỆP VỤ
                </p>
              </div>

              <div className="flex w-[500px]">
                <p className="bg-[#f3e522] p-2 pl-16 w-full">
                  {renderDeviceInfo()}
                </p>
                <div className="triangle-bottomleft"></div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="text-white font-bold pr-4">
            <Dropdown
              trigger={
                <span className="text-white font-bold">
                  {`${t("Hi")} ${user.name}`}{" "}
                  <Image src={user.avatar || "/default-avatar.png"} avatar />
                </span>
              }
              direction="left"
              pointing
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="user"
                  text={t("Profile")}
                  as="a"
                  href="#/profile"
                />
                <Dropdown.Item
                  icon="log out"
                  text={t("Log out")}
                  onClick={onLogoutClick}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <Menu fluid fixed="top">
      <Link to="/" className="header-yellow">
        <Menu.Item className="w-[197px] p-4 header-yellow">
          <div className="font-bold p-4">
            <p>BỘ CÔNG AN</p>
            <p className="text-[10px]">CỤC CẢNH SÁT GIAO THÔNG</p>
          </div>
        </Menu.Item>
      </Link>

      <Menu.Item
        className="header-yellow p-4"
        onClick={() => {
          toggleMenu();
        }}
      >
        <div className="p-4">
          <i className="icon bars" />
        </div>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          toggleMenu();
        }}
      >
        <div className="bg-[#392272] font-bold">
          <div className="flex">
            <div className="triangle-bottomleft"></div>
            <p className="text-white p-2 bg-[#392272]">
              HỆ THỐNG QUẢN LÝ THIẾT BỊ KỸ THUẬT NGHIỆP VỤ
            </p>
          </div>

          <div className="flex w-[500px]">
            <p className="bg-[#f3e522] p-2 pl-16 w-full">
              {Config.DeviceType === 0
                ? "THIẾT BỊ ĐO NỒNG ĐỘ CỒN"
                : "THIẾT BỊ ĐO TỐC ĐỘ"}
            </p>
            <div className="triangle-bottomleft"></div>
          </div>
        </div>
      </Menu.Item>
      <Menu.Menu position="right" className="font-bold text-white">
        <Menu.Item>
          <Language />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
}
