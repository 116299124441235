export enum DialogType {
  MessageDialog,
  ConfirmDialog,
}
export enum AlertType {
  Success,
  Info,
  Warning,
  Danger,
}
export interface ConfirmCallback {
  (result: boolean): void;
}
export interface Dialog {
  id?: number;
  type: DialogType;
  label?: string;
  content: string;
  onClose: Function;
  onResult: ConfirmCallback;
}

export interface CourseBaseEntity {
  id: number;
  name: string;
  book_name: string;
  note: string;
  course_type: CourseType;
  price: number;
}
export interface GridEntity {
  name: string;
  label: string;
  api: string;
  buttons: Button[];
  columns: GridColumn[];
  filter: SearchItem[];
  bread_crumb: BreadCrumb[];
}
export interface UserEntity {
  id: number;
  name: string;
  user_name: string;
  password: string;
  role: UserRole;
  email: string;
  phone: string;
  avatar?: string;
  birth: Date;
  nationality: string;
  skype?: string;
  createdAt: Date;
  updatedAt: Date;
  is_follow: boolean;
  follower_id: number;
  follower: UserEntity;
  bank_card: string;
  bank: string;
  bank_user_name: string;
  version: number;

  student_number_of_course: number;
  student_number_of_meet: number;
  student_number_of_met: number;
  student_number_of_absent: number;
  student_cancel_month: number;
  student_total_cancel: number;

  teacher_number_registered_time_in_month: number;
  teacher_number_met: number;
  teacher_number_feedback_to_do: number;
  teacher_cancel_month: number;
  teacher_total_cancel: number;
  teacher_youtube_channel: string;
  teacher_subjects: number[];
  teacher_rating: number;
  teacher_major: string;
  teacher_experience: string;
  teacher_certificate: string;
  teacher_other_skill: string;
  courses: CourseEntity[];
}
export interface BreadCrumb {
  name: string;
  url: string;
}
export interface MenuEntity {
  name: string;
  url: string;
  icon: string;
  roles: UserRole[];
  parent_id: number;
  is_parent: boolean;
  id: number;
  bread_crumb: BreadCrumb[];
}
export interface EnumEntity {
  name: string;
  items: EnumItem[];
  value_type: "string" | "number";
}
export interface TimeEntity {
  id: number;
  version: number;
  teacher_id: number;
  teacher: UserEntity;
  student_id: number;
  student: UserEntity;
  start_date: Date;
  time_status: TimeStatus;
  is_feedback: boolean;
  is_notify: boolean;
  feedback: FeedbackEntity;
  course_id: number;
  course: CourseEntity;
}
export interface CourseEntity {
  id: number;
  course_base_id: number;
  courseBase: CourseBaseEntity;
  name: string;
  book_name: string;
  course_type: CourseType;
  student_id: number;
  student: UserEntity;
  teacher_id: number;
  teacher: UserEntity;
  feedbacks: FeedbackEntity[];
  current_page: number;
  note: string;
  number_of_meet: number;
  number_of_used: number;
  course_status: CourseStatus;
  times: TimeEntity[];
  approved_by: number;
  approved_at: Date;
  price: number;
  created_by: number;
  meeting_url: string;
  convert_from_id: number;
  convertFrom: UserEntity;
}
export interface FeedbackEntity {
  feedback_type: FeedbackType;
  feedback_status: FeedbackStatus;
  reject_reason: string;
  time_id: number;
  time: TimeEntity;
  course_id: number;
  course: CourseEntity;
  teacher_id: number;
  teacher: UserEntity;
  student_id: number;
  student: UserEntity;
  content: object;
  created_by: number;
  createdBy: UserEntity;
  edited_by: number;
  approved_by: number;
  rejected_by: number;
  approved_at: Date;
  is_send_mail: boolean;
}
export interface FormEntity {
  name: string;
  label: string;
  api: number;
  buttons: Button[];
  controls: FormControl[];
  bread_crumb: BreadCrumb[];
}

export enum FeedbackType {
  Trial,
  Daily,
  Period,
  EndCourse,
}
export enum FeedbackStatus {
  Pending,
  WaitingForEdit,
  WaitingForApprove,
  Approved,
  Reject,
}
export enum UserRole {
  Admin,
  Teacher,
  Student,
  CustomerCare,
  Academic,
  Agent,
  AcademicManager,
}
export enum TimeStatus {
  Available,
  Booked,
  Meeting,
  WaitingForFeedback,
  Done,
  Cancel,
}
export enum NotificationType {
  Message,
  NewUserRegister,
}
export enum CellDisplay {
  Text = "text",
  Number = "number",
  Date = "date",
  Image = "image",
  Switch = "switch",
  Enum = "enum",
  Entity = "entity",
  JSON = "json",
  Entities = "entities",
  ArrayString = "arraystring",
  File = "file",
}
export interface SearchItem {
  label: string;
  field: string;
  type: "text" | "number" | "date";
  control?: "input" | "enum" | "date" | "entity";
  isRange: boolean;
  enum?: string;
  multiple?: boolean;
  gridName?: string;
}
export interface GridColumn {
  postfix?: string;
  label: string;
  field: string;
  sortField?: string;
  display: CellDisplay;
  api?: string;
  sorter?: boolean;
  defaultSortOrder?: string;
  enumName?: string;
  fixed?: string;
  width: number;
}
export interface Button {
  label: string;
  color: string;
  pageMode?: string;
  classes?: string;
  icon?: string;
  showInColumn?: string;
  action: "api" | "redirect" | "imageInfo" | "export";
  api?: string;
  redirectUrl?: string;
  redirectUrlEmbed?: any;
  embedData?: object;
  popupData?: object;
  confirmText?: string;
  backOnAction?: boolean;
  position?: string;
  successMessage?: string;
  failMessage?: string;
  disableReload?: boolean;
  hideExpression?: object;
}
export enum SchemaDataType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Array = "array",
  Object = "object",
}
export enum SchemaControl {
  Enum = "enum",
  Text = "text",
  TextArea = "textarea",
  Image = "image",
  ArrayImage = "arrayimage",
  Location = "location",
  RichText = "richtext",
  Password = "password",
  Captcha = "captcha",
  Label = "label",
  Step = "step",
  Entity = "entity",
  JSON = "json",
  Date = "date",
  TextArray = "textarray",
  Divider = "divider",
  CheckList = "checklist",
  Schema = "schema",
  Teacher = "teacher",
  Upload = "upload",
  Checkbox = "checkbox",
}

export interface FormControl {
  label: string;
  field: string;
  dataType: SchemaDataType;
  caption?: string;
  required: boolean;
  disabled: boolean;
  control: SchemaControl;
  gridName: string;
  schemaName: string;
  enum: string;
  hideExpression?: object;
  requireExpression?: object;
  api: string;
  multiple?: boolean;
}
export enum Language {
  English,
  Chinese,
}
export interface EnumItem {
  value: string;
  label: string;
  color?: string;
}
export enum CourseStatus {
  WaitingForApprove,
  Approve,
  Cancel,
  Expired,
}

export enum CourseType {
  Trial,
  Official,
}
export interface TeamEntity {
  name: string;
}
export interface DeviceEntity {
  name: string;
  serial: string;
  is_online: boolean;
  last_online_time: Date;
  is_active: boolean;
  issue_date: Date;
  issue_paper: string;
  check_date: Date;
  check_duration: number;
  start_use: Date;
  manager_team_id: number;
  manager_team: TeamEntity;
  user_team_id: number;
  user_team: TeamEntity;
  user_id: number;
  user: UserEntity;
  user_shift: string;
  location: string;
  violate: number;
  stopped: number;
  violate_noti: number;
  lat: number;
  lng: number;
}
export enum ViolateType {
  Alcohol,
  Speed,
}
export interface ViolateEntity {
  id: number;
  device_id: number;
  device: DeviceEntity;
  create_date: Date;
  is_approve: boolean;
  manager_team_id: number;
  manager_team: TeamEntity;
  status: number;
  user_team_id: number;
  user_team: TeamEntity;
  is_send_noti: boolean;
  is_inline_process: boolean;
  location: string;
  type: ViolateType;
  speed: number;
  plate: string;
  plate_color: string;
  image: string;
  vehicle_id: string;
  vehicle_name: string;
  owner: string;
  address: string;
  color: string;
  manufacture: string;
  code: string;
  seat: number;
  violate: string;
  base: string;
  min_speed: number;
  max_speed: number;
  phone: string;
  vehicle_type: string;
  type_number: string;
  weight: string;
  frame_number: string;
  capacity: number;
  register_number: string;
  expire_date: Date;
  register_date: Date;
  register_expire: Date;
  register_first_date: Date;
  version: number;
}
