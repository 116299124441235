import { Routes, Route } from "react-router-dom";
import Profile from "profile";
import Home from "home";
import List from "list";
import Form from "form";
import { FolderImages, ListFolder } from "folder";
import TeamInfo from "team";
import ReportDashboard from "report/dashboard";
import ReportDevice from "report/device";
import ApproveViolate from "violate/approve-violate";
import ChangePassword from "changepassword";
import ApproveAlcoholViolate from "violate/approve-alcohol-violate";

export default function Main({ showMenu }: { showMenu: boolean }) {
  return (
    <div>
      <div
        style={{ minHeight: "100vh" }}
        className={`p-4 pt-24 bg-[#ecf0f1] duration-200 ${
          showMenu && "pl-[260px]"
        }`}
      >
        <Routes>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/" element={<Home />}></Route>
          <Route path="/list/:name" element={<List />}></Route>
          <Route path="/form/:name/:mode" element={<Form />}></Route>
          <Route path="/list-folder" element={<ListFolder />} />
          <Route path="/folder-images" element={<FolderImages />} />
          <Route path="/team-info" element={<TeamInfo />} />
          <Route path="/report/dashboard" element={<ReportDashboard />} />
          <Route path="/report/device" element={<ReportDevice />} />
          <Route path="/violate/approve/:status" element={<ApproveViolate />} />
          <Route
            path="/alcohol-violate/approve/:status"
            element={<ApproveAlcoholViolate />}
          />
          <Route path="/changepassword" element={<ChangePassword />} />
        </Routes>
      </div>
    </div>
  );
}
