import {
  Button as IButton,
  GridColumn,
  CellDisplay,
  GridEntity,
} from "interfaces";
import { useState, useEffect, useMemo } from "react";
import Config from "config";
import dayjs from "dayjs";
import dataService from "../../services/data";
import { useTranslation } from "react-i18next";
import Lightbox from "react-image-lightbox";
import qs from "querystring";
import { Empty, Loading, ViewEnum } from "components";
import utils from "../../services/utils";
import { api, ui } from "services";
import {
  Button,
  Card,
  Dropdown,
  Icon,
  Image,
  Label,
  Modal,
  Pagination,
  Table,
} from "semantic-ui-react";
import FilterForm from "./filter-form";
import ImageInfo from "folder/image-info";
interface GridProps {
  embedFilter?: any;
  gridName: string;
  canSelect?: boolean;
  selectedItems?: any[];
  onChangeSelectedItems?: Function;
  onItemSelected?: Function;
  disableButton?: boolean;
}
function getFieldData(obj: any, field: string) {
  let arr = field.split(".");
  if (arr.length) {
    let rs = obj;
    arr.forEach((f) => {
      if (rs === null || rs === undefined) {
        return rs;
      }
      rs = rs[f];
    });
    return rs;
  } else {
    return obj[field];
  }
}
function GridView({
  embedFilter,
  gridName,
  canSelect,
  selectedItems,
  onItemSelected,
  disableButton,
}: GridProps): React.ReactElement {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const [viewImage, setViewImage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [gridInfo, setGridInfo] = useState<GridEntity>(null);
  const [total, setTotal] = useState<number>(0);
  const [order, setOrder] = useState<[field: string, order: "DESC" | "ASC"]>([
    "id",
    "DESC",
  ]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(Config.PageSize);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>({});
  const [query, setQuery] = useState<any>({});
  const [openImageInfo, setOpenImageInfo] = useState<boolean>(false);
  const [activeImage, setActiveImage] = useState<any>(null);
  const [timestamp, setTimestamp] = useState<number>(0);
  function getFileName(str: string) {
    if (!str) return str;
    return str.split("/").pop();
  }
  const columns = useMemo((): GridColumn[] => {
    if (!gridInfo) return [];
    const cols = [];
    gridInfo.columns.forEach((i) => {
      let tmp = {
        label: i.label,
        field: i.field,
        sorter: true,
        sortField: i.sortField,
        postfix: i.postfix,
        render: (val: any, row: any) => {
          switch (i.display) {
            case CellDisplay.File:
              return (
                <a target="_blank" href={val}>
                  {getFileName(val)}
                </a>
              );
            case CellDisplay.Image:
              if (val) {
                return (
                  <Image
                    alt="cell image"
                    src={val || "/default.jpg"}
                    size="tiny"
                    rounded
                    onClick={() => {
                      setViewImage(val);
                    }}
                  />
                );
              } else {
                return (
                  <Image
                    alt="cell image"
                    size="tiny"
                    src={val || "/default.jpg"}
                    rounded
                  />
                );
              }
            case CellDisplay.Enum:
              return <ViewEnum enumName={i.enumName} value={Number(val)} />;
              break;
            case CellDisplay.Text:
              return <span>{val}</span>;
            case CellDisplay.Date:
              return <span>{dayjs(val).format("DD/MM/YYYY HH:mm:ss")}</span>;
            case CellDisplay.ArrayString:
              return (
                <>
                  {val?.map((i: any, iIndex: number) => (
                    <Label>{i}</Label>
                  ))}
                </>
              );
          }
        },
      };
      cols.push(tmp);
    });
    const rowButtons = gridInfo?.buttons?.filter((i) => i.position === "row");
    if (rowButtons.length && !disableButton) {
      cols.push({
        label: "Actions",
        field: "",
        display: CellDisplay.Text,
        fixed: "right",
        width: 80,
        //@ts-ignore
        render: (val, row) => {
          return rowButtons.map((item, index) =>
            renderButton(item, index, row)
          );
        },
      });
    }
    return cols;
  }, [gridInfo]);
  useEffect(() => {
    let rs = dataService.getGrid(gridName);
    if (!rs) {
      return console.error("Grid not found: ", gridName);
    }
    setFilter({});
    setOrder(["id", "DESC"]);
    setCurrentPage(0);
    setPageSize(Config.PageSize);
    setTotal(0);
    setData([]);
    setGridInfo(rs);
  }, [gridName]);

  async function fetchReport() {
    if (!gridInfo) {
      return;
    }
    let where = calculateWhere();
    let tmp = Object.assign({}, where, embedFilter || {});
    setLoading(true);
    let queryOption: any = {
      where: tmp,
      offset: 0,
      limit: 100000000,
      order: [order],
    };
    let rs = await api.post(`${gridInfo.api}`, queryOption);
    api.downloadReport(rs.data);
  }
  function calculateWhere() {
    let where: any = {};
    if (gridInfo?.filter && gridInfo?.filter?.length > 0) {
      gridInfo.filter.forEach((item) => {
        if (query[item.field] === undefined) {
          return;
        }
        if (item.multiple && !(query[item.field] && query[item.field].length)) {
          return;
        }
        if (query[item.field] !== undefined) {
          switch (item.type) {
            case "date":
              if (!where[item.field]) {
                where[item.field] = {};
              }
              if (query[item.field][0]) {
                where[item.field]["$gte"] = query[item.field][0];
              }
              if (query[item.field][1]) {
                where[item.field]["$lte"] = query[item.field][1];
              }
              break;
            case "text":
              where[item.field] = { $substring: query[item.field] };
              break;
            default:
              where[item.field] = query[item.field];
              break;
          }
        }
      });
    }
    return where;
  }
  useEffect(() => {
    let isMount = true;
    async function fetch({ offset, limit, order, where }: any) {
      if (!gridInfo) {
        return;
      }
      let tmp = Object.assign({}, where, embedFilter || {});
      setLoading(true);
      let queryOption: any = {
        where: tmp,
        offset,
        limit,
        order,
      };
      let rs = await api.post(`${gridInfo.api}`, queryOption);
      if (!isMount) return;
      setTotal(rs?.count);
      setData(rs?.data);
      setLoading(false);
    }
    let where: any = calculateWhere();

    fetch({
      offset: currentPage * pageSize,
      limit: pageSize,
      order: [order],
      where,
    });
    return () => {
      isMount = false;
    };
  }, [gridInfo, currentPage, pageSize, order, query, timestamp, embedFilter]);
  if (!gridInfo) {
    return <p>{t("Loading...")}</p>;
  }
  function renderButton(btn: IButton, index: number, item?: any) {
    if (disableButton) {
      return null;
    }
    if (btn.hideExpression) {
      if (utils.checkExpression(btn.hideExpression, item)) {
        return;
      }
    }
    switch (btn.action) {
      case "imageInfo":
        return (
          <Button
            className="mr-1"
            icon={btn.icon}
            content={t(btn.label)}
            size={btn.position === "top" ? "medium" : "mini"}
            key={index}
            primary
            onClick={async () => {
              setActiveImage(item);
              setOpenImageInfo(true);
            }}
          />
        );
      case "api":
        return (
          <Button
            className="mr-1"
            icon={btn.icon}
            // @ts-ignore
            color={btn.color || "blue"}
            content={t(btn.label)}
            size={btn.position === "top" ? "medium" : "mini"}
            key={index}
            onClick={async () => {
              if (btn.confirmText) {
                await ui.confirm(t(btn.confirmText));
              }
              try {
                await api.post(btn.api, item || { code: 0 });
                setTimestamp(new Date().getTime());
                ui.alert(t(btn.successMessage || "Success"));
              } catch (error) {
                ui.alert(t(btn.failMessage || "Fail"));
              }
            }}
          />
        );
      case "export":
        return (
          <Button
            className="mr-1"
            icon="download"
            // @ts-ignore
            color={btn.color || "teal"}
            content={t(btn.label)}
            size={btn.position === "top" ? "medium" : "mini"}
            key={index}
            onClick={async () => {
              fetchReport();
            }}
          />
        );
      case "redirect":
        let url = btn.redirectUrl;
        //check data item
        if (btn.redirectUrlEmbed && Object.keys(btn.redirectUrlEmbed).length) {
          let params: any = {};
          for (var i in btn.redirectUrlEmbed) {
            let val = btn.redirectUrlEmbed[i];
            if (typeof val === "string" && val[0] === "$") {
              let key = val.substr(1, val.length - 1);
              params[i] = item[key];
            } else {
              params[i] = val;
            }
          }
          let embed: any = {};
          if (btn.redirectUrlEmbed.embed) {
            for (var i in btn.redirectUrlEmbed.embed) {
              let val = btn.redirectUrlEmbed.embed[i];
              if (typeof val === "string" && val[0] === "$") {
                let key = val.substr(1, val.length - 1);
                embed[i] = item[key];
              } else {
                embed[i] = val;
              }
            }
            params.embed = JSON.stringify(embed);
          }
          url += `?${qs.stringify(params)}`;
        }
        if (item) {
          for (var i in item) {
            url = url.replace(new RegExp(`{${i}}`, "g"), item[i]);
          }
        }
        return (
          <Button
            as="a"
            size={btn.position === "top" ? "medium" : "mini"}
            icon={btn.icon}
            href={`#${url}`}
            key={index}
            primary
            content={t(btn.label)}
          />
        );
    }
  }
  function isSelect(item: any): boolean {
    for (var i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i]?.id === item.id) {
        return true;
      }
    }
    return false;
  }
  function onItemSelect(item: any): void {
    onItemSelected(item);
  }
  return (
    <div>
      <ImageInfo
        imageInfo={activeImage}
        open={openImageInfo}
        onClose={() => {
          setOpenImageInfo(false);
        }}
      />
      {viewImage && (
        <Lightbox
          mainSrc={viewImage}
          onCloseRequest={() => {
            setViewImage("");
          }}
        />
      )}
      {/* <Modal open={openFilter} onClose={() => setOpenFilter(false)} size="tiny">
        <Modal.Header>{t("Filter")}</Modal.Header>
        <Modal.Content>
          <FilterForm
            setQuery={(q: any) => {
              setQuery(q);
            }}
            onChange={setFilter}
            values={filter || {}}
            filter={gridInfo.filter}
            onClose={() => {
              setOpenFilter(false);
            }}
          />
        </Modal.Content>
      </Modal> */}
      <div>
        <div className="flex justify-between border-none">
          <div className="flex">
            <p className="bg-white p-2 font-bold text-[#776d63] h-[34px]">
              {t(gridInfo.label)}
            </p>
            <div className="triangle-bottomleft-white"></div>
          </div>
          {gridInfo.filter?.length > 0 && (
            <div className="bg-white flex text-[#776d63] h-[34px]">
              <div className="triangle-bottomleft-grey"></div>
              <p
                className="font-bold flex p-2 gap-2 cursor-pointer"
                onClick={() => {
                  setOpenFilter(!openFilter);
                }}
              >
                <span>Tìm kiếm</span>
                <span>
                  {openFilter ? (
                    <Icon name="arrow up" />
                  ) : (
                    <Icon name="arrow down" />
                  )}
                </span>
              </p>
            </div>
          )}
        </div>
        <div className="bg-white p-2">
          <div>
            {gridInfo.filter && openFilter && (
              <FilterForm
                setQuery={(q: any) => {
                  setQuery(q);
                }}
                onChange={setFilter}
                values={filter || {}}
                filter={gridInfo.filter}
                onClose={() => {
                  setOpenFilter(false);
                }}
              />
            )}
          </div>
          <div className="flex justify-between py-2 items-center">
            <div>
              <p className="font-bold">
                Tổng số <span className="text-red-600">{total}</span>
              </p>
            </div>
            <div>
              {gridInfo.buttons
                .filter((i) => i.position === "top")
                .map((button, index) => renderButton(button, index))}
              {/* {gridInfo.filter?.length && (
                <Button
                  icon="search"
                  content={t("Filter")}
                  basic
                  primary
                  labelPosition="left"
                  className="ml-2 relative"
                  onClick={() => {
                    setOpenFilter(true);
                  }}
                />
              )} */}
            </div>
          </div>
          <div
            className="block w-full overflow-x-auto relative"
            style={{ height: "calc(100vh - 270px)" }}
          >
            <Table celled sortable>
              <Table.Header>
                <Table.Row>
                  {canSelect && (
                    <Table.HeaderCell className="sticky top-0 px-3 text-white align-middle border border-solid border-blueGray-100 py-2 text-base border-l-0 border-r-0 border-t-0 whitespace-nowrap font-normal text-left cursor-pointer z-20 bg-gradient-to-t from-primary-400 to-primary-600">
                      {t("Select")}
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell>STT</Table.HeaderCell>
                  {columns.map((col, index) => {
                    let className =
                      "sticky top-0 px-3 text-white align-middle border border-solid border-blueGray-100 py-2 text-base border-l-0 border-r-0 border-t-0 whitespace-nowrap font-normal text-left cursor-pointer z-20 bg-gradient-to-t from-primary-400 to-primary-600";
                    if (col.fixed === "right") {
                      className += " right-0";
                    }
                    return (
                      <Table.HeaderCell
                        key={index}
                        sorted={
                          order[0] === (col.sortField || col.field) &&
                          col.sorter
                            ? order[1] === "DESC"
                              ? "descending"
                              : "ascending"
                            : null
                        }
                        onClick={() => {
                          if (!col.sorter) return;
                          let f = col.sortField || col.field;
                          let s: [field: string, order: "DESC" | "ASC"] = [
                            ...order,
                          ];
                          if (s[0] === f) {
                            s[1] = s[1] === "DESC" ? "ASC" : "DESC";
                          } else {
                            s[0] = f;
                            s[1] = "DESC";
                          }
                          setOrder(s);
                        }}
                        className={className}
                      >
                        {t(col.label)}
                      </Table.HeaderCell>
                    );
                  })}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.map((dt, dtIndex) => (
                  <Table.Row
                    key={dtIndex}
                    className={`hover:bg-primary-100 ${
                      canSelect && "cursor-pointer"
                    }`}
                    onClick={() => {
                      if (!canSelect) return;
                      onItemSelect(dt);
                    }}
                  >
                    {canSelect && (
                      <Table.Cell className="text-center">
                        <div className="h-10 w-32 cursor-pointer flex items-center justify-center">
                          <i
                            className={`fas fa-check text-4xl text-green-700 duration-300 ${
                              isSelect(dt) ? "opacity-100" : "opacity-0"
                            } `}
                          />
                        </div>
                      </Table.Cell>
                    )}
                    <Table.Cell>{dtIndex + 1}</Table.Cell>
                    {columns.map((col, colIndex) => {
                      console.log({ col });
                      let className =
                        "border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2 text-left";
                      if (col.fixed === "right") {
                        className += " sticky right-0 bg-white";
                      }
                      return (
                        <Table.Cell key={colIndex} className={className}>
                          {
                            //@ts-ignore
                            col.render
                              ? //@ts-ignore
                                col.render(getFieldData(dt, col.field), dt)
                              : getFieldData(dt, col.field)
                          }
                          <span>{col.postfix}</span>
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            {!loading && data.length === 0 ? <Empty /> : null}
          </div>
        </div>
        <div className="bg-white p-2">
          <div className="flex justify-between p-4 pb-0">
            <div className="flex gap-2 items-center">
              <div className="w-52 relative">
                <Dropdown
                  onChange={(evt: any, { value }) => {
                    console.log(value);
                    setPageSize(Number(value));
                  }}
                  value={pageSize}
                  fluid
                  selection
                  options={[
                    { value: 10, text: `10 ${t("items per page")}` },
                    { value: 20, text: `20 ${t("items per page")}` },
                    { value: 50, text: `50 ${t("items per page")}` },
                  ]}
                />
              </div>

              {loading && (
                <>
                  <Loading /> <p>{t("Loading")}</p>
                </>
              )}
            </div>
            <div className="pagination">
              <Pagination
                activePage={currentPage + 1}
                onPageChange={(page: any, rs: any) => {
                  setCurrentPage(rs.activePage - 1);
                }}
                size="mini"
                totalPages={Math.ceil(total / pageSize)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GridView;
