import { Empty, Loading } from "components";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, Image } from "semantic-ui-react";
import { api, ui } from "services";
import ImageInfo from "./image-info";

export default function FolderImages() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  const [activeImage, setActiveImage] = useState(null);
  const [openImageInfo, setOpenImageInfo] = useState(false);
  const query = new URLSearchParams(search);
  const date = Number(query.get("date"));
  async function loadImages() {
    try {
      setLoading(true);
      let { data } = await api.post("/api/get-list-image-by-folder", {
        where: { folder_date: date },
      });
      setData(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    loadImages();
  }, []);
  async function onDelete(item: any) {
    await ui.confirm("Bạn có chắc chắn muốn xóa ảnh này không?");
    await api.post("/api/delete-image", { id: item.id });
    loadImages();
  }
  return (
    <Card fluid>
      <Card.Content header={`Hình ảnh trong ngày ${date}`}></Card.Content>
      <Card.Content>
        <ImageInfo
          imageInfo={activeImage}
          open={openImageInfo}
          onClose={() => {
            setOpenImageInfo(false);
          }}
        />
        {loading ? (
          <Loading />
        ) : (
          <div className="flex flex-wrap">
            {data.map((i) => (
              <div className="p-2">
                <Card>
                  <Card.Content
                    onClick={() => {
                      setOpenImageInfo(true);
                      setActiveImage(i);
                    }}
                  >
                    <Image src={i.image} />
                    <p className="mt-4">
                      Thời gian: {dayjs(i.created_at).format("HH:mm")}
                    </p>
                    <p>Đơn vị: {i.team}</p>
                  </Card.Content>
                  <Card.Content>
                    <Button
                      onClick={() => {
                        onDelete(i);
                      }}
                      content="Xóa"
                      icon="trash"
                      labelPosition="left"
                      color="red"
                    />
                  </Card.Content>
                </Card>
              </div>
            ))}
            {!data.length && <Empty />}
          </div>
        )}
      </Card.Content>
    </Card>
  );
}
