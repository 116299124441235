import { FormView } from "components";
import { useLocation, useParams } from "react-router-dom";

export default function Form() {
  const { name, mode } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = Number(query.get("id"));
  const embed = query.get("embed");
  return (
    <div>
      <FormView
        formName={name}
        params={{
          mode,
          id,
          embed,
        }}
      />
    </div>
  );
}
