import { GridView } from "components";
import Bread from "components/bread-crumb";
import { useParams } from "react-router-dom";
import { data } from "services";

export default function List() {
  const { name } = useParams();
  let grid = data.getGrid(name);
  return (
    <div>
      {/* <Bread data={grid.bread_crumb} /> */}
      <div>
        <GridView gridName={name} />
      </div>
    </div>
  );
}
