import axios from "axios";
import Config from "config";
// @ts-ignore
import { saveAs } from "file-saver";
function getDeviceType(): string {
  switch (process.env.REACT_APP_DEVICE_TYPE) {
    case "-1":
      let raw = localStorage.getItem("device_type");
      if (!raw) {
        raw = "0";
        localStorage.setItem("device_type", raw);
      }
      return raw;
    default:
      return process.env.REACT_APP_DEVICE_TYPE;
  }
}
async function post(url: string, data: any): Promise<any> {
  let rs = await fetch(`${Config.ApiHost}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      clientid: getDeviceType(),
    },
    body: JSON.stringify(data),
  });
  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    case 403:
      window.location.href = "#/login";
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}
async function downloadReport(data: any) {
  const labels = Object.keys(data[0]);
  console.log({ data });
  let csv = [labels];
  data.forEach((i: any) => {
    csv.push(
      labels.map((f: string) => {
        switch (typeof i[f]) {
          case "object":
            return i[f]?.name;
            break;
          default:
            return i[f];
        }
      })
    );
  });
  // for (var i = 0; i < data.labels.length; i++) {
  //   csv[i + 1] = [data.labels[i]];
  //   data.datasets.forEach((s: any) => {
  //     csv[i + 1].push(s.data[i]);
  //   });
  // }
  // let text = csv.map((s: string[]) => s.join(",")).join("\n");
  // var blob = new Blob([text], {
  //   type: "text/plain;charset=utf-8;",
  // });
  // saveAs(blob, "report.csv");
  ExportData(data);
}
function ExportData(data: any[]) {
  console.log("export data ", data);
  let filename = "reports.xlsx";
  // @ts-ignore
  let XLSX = window.XLSX;
  var ws = XLSX.utils.json_to_sheet(data);
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "People");
  XLSX.writeFile(wb, filename);
}
async function postFormData(
  url: string,
  data: any,
  cb?: Function
): Promise<any> {
  var formdata = new FormData();
  for (var i in data) {
    formdata.append(i, data[i]);
  }
  let tmp: any = await axios.request({
    method: "post",
    url: `${Config.ApiHost}${url}`,
    data: formdata,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "multipart/form-data",
      clientid: getDeviceType(),
    },
    onUploadProgress: (p) => {
      if (cb) cb(p);
      //this.setState({
      //fileprogress: p.loaded / p.total
      //})
    },
  });
  if (tmp.code === "forbidden") {
    window.location.href = "/auth";
    throw new Error("forbidden");
  }
  return tmp.data;
}
async function uploadImage(image: any): Promise<any> {
  return postFormData(`${Config.ApiHost}/file/upload-image`, {
    image,
  });
}
const api = { post, postFormData, uploadImage, downloadReport, getDeviceType };
export default api;
