import { Modal, Tab, Image } from "semantic-ui-react";
//@ts-ignore
import ReactImageZoom from "react-image-zoom";
import { FormView } from "components";
import Lightbox from "react-image-lightbox";
import { useState } from "react";

export default function ImageInfo({ imageInfo, open, onClose }: any) {
  const [showImage, setShowImage] = useState(false);
  return (
    <Modal
      open={open}
      closeIcon
      onClose={() => {
        onClose();
      }}
    >
      <Modal.Header>Hình ảnh</Modal.Header>
      <Modal.Content>
        {showImage && (
          <Lightbox
            mainSrc={imageInfo.image}
            onCloseRequest={() => {
              setShowImage(false);
            }}
          />
        )}
        {imageInfo && (
          <div>
            <Tab
              panes={[
                {
                  menuItem: "Xem hình ảnh",
                  render: () => {
                    return (
                      <div className="p-4 flex justify-center">
                        <Image
                          size="large"
                          src={imageInfo.image}
                          onClick={() => {
                            setShowImage(true);
                          }}
                        />
                      </div>
                    );
                  },
                },
                {
                  menuItem: "Biên bản",
                  render: () => {
                    return (
                      <div className="p-4">
                        <FormView
                          formName={"report"}
                          params={{
                            mode: "edit",
                            id: imageInfo.id,
                          }}
                        />
                      </div>
                    );
                  },
                },
              ]}
            />
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
}
