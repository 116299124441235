import { MenuEntity } from "interfaces";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "reducer/store";
import { Icon } from "semantic-ui-react";
import { data } from "services";
export default function Sidebar({ showMenu }: { showMenu: boolean }) {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);
  const nav = useMemo(() => {
    return data.getMenus();
  }, [user]);
  const location = useLocation();
  const [open, setOpen] = useState<number[]>([]);
  function toggleMenu(item: MenuEntity) {
    let tmp = _.cloneDeep(open);
    if (tmp.includes(item.id)) {
      tmp = tmp.filter((i) => i !== item.id);
    } else {
      tmp.push(item.id);
    }
    console.log("toggle", tmp);
    setOpen(tmp);
  }
  return (
    <div
      className={`duration-200 fixed ${
        showMenu ? "left-0" : "left-[-250px]"
      } left-0 top-0 z-10 pt-16 w-[250px] h-screen overflow-auto bg-[#392272]`}
    >
      <div className="p-3 pt-6">
        {nav
          .filter((i) => i.parent_id === 0 && !i.is_parent)
          .filter((i) => i.roles.includes(user.role))
          .map((i, index) => (
            <a
              className="text-white p-3 rounded-md cursor-pointer hover:bg-slate-700 w-full inline-block"
              href={`#${i.url}`}
              key={index}
              // active={location.pathname === i.url}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <p className="w-8 text-center">
                    {/* @ts-ignore */}
                    <Icon name={i.icon} className="text-[#f3e522]" />
                  </p>
                  <p>{t(i.name)}</p>
                </div>
              </div>
            </a>
          ))}
        {nav
          .filter((i) => i.is_parent)
          .filter((i) => i.roles.includes(user.role))
          .map((i, index) => (
            <div>
              <a
                className="text-white p-3 cursor-pointer hover:bg-[#eed4b3] hover:text-black w-full inline-block"
                key={index}
                onClick={() => {
                  toggleMenu(i);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <p className="mr-2 w-8 text-center">
                      {/* @ts-ignore */}
                      <Icon
                        // @ts-ignore
                        name={i.icon}
                        className="text-[#f3e522]"
                        size="large"
                      />
                    </p>
                    <p>{t(i.name)}</p>
                  </div>
                  {open.includes(i.id) ? (
                    <Icon name="arrow down" />
                  ) : (
                    <Icon name="arrow right" />
                  )}
                </div>
              </a>
              {open.includes(i.id) && (
                <div className="pl-4">
                  <div className="border-l border-slate-500">
                    {nav
                      .filter((child) => child.parent_id === i.id)
                      .filter((child) => child.roles.includes(user.role))
                      .map((child, index) => (
                        <a
                          className="text-white p-3 cursor-pointer hover:bg-[#eed4b3] w-full inline-block"
                          href={`#${child.url}`}
                          key={index}
                          // active={location.pathname === child.url}
                        >
                          <div className="flex items-center">
                            <p className="mr-2 w-8 text-center">
                              {/* @ts-ignore */}
                              <Icon name={child.icon} />
                            </p>
                            <p>{t(child.name)}</p>
                          </div>
                        </a>
                      ))}
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
