import { Loading } from "components";
import { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { Card, Header } from "semantic-ui-react";
import { api } from "services";
import dayjs from "dayjs";
export default function ListFolder() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [months, setMonths] = useState([]);
  useEffect(() => {
    async function loadListFolder() {
      try {
        setLoading(true);
        let { count, data } = await api.post(
          "/api/get-list-folder-by-city",
          {}
        );
        data.sort((a: any, b: any) => b.date - a.date);
        let months: string[] = [];
        data.forEach((e: any) => {
          let day = dayjs(String(e.date), "YYYYMMDD");
          e.month = day.format("MM-YYYY");
          e.display = day.format("DD-MM-YYYY");
          if (!months.includes(e.month)) {
            months.push(e.month);
          }
        });
        console.log({ months, data });
        setMonths(months);
        setData(data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    loadListFolder();
  }, []);
  return (
    <Card fluid>
      <Card.Content header="Hình ảnh"></Card.Content>
      <Card.Content>
        {loading ? (
          <Loading />
        ) : (
          <div>
            {months.map((m: string) => (
              <div className="mb-8">
                <Header color="blue">Tháng {m}</Header>
                <div className="flex flex-wrap">
                  {data
                    .filter((i) => i.month === m)
                    .map((i) => (
                      <div className="w-1/3 lg:w-1/4 p-2 cursor-pointer">
                        <Card
                          as="a"
                          href={`#/folder-images?date=${i.date}`}
                          fluid
                          header={dayjs(String(i.date), "YYYYMMdd").format(
                            "DD-MM-YYYY"
                          )}
                          meta={`Số lượng: ${i.quantity}`}
                        />
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </Card.Content>
    </Card>
  );
}
