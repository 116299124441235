import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import GoogleMapReact from "google-map-react";
import { useEffect, useState } from "react";
import { api } from "services";
import { Button, Modal } from "semantic-ui-react";
import { DeviceEntity } from "interfaces";
import dayjs from "dayjs";
export default function Home() {
  const user = useSelector((state: RootState) => state.user);
  const [devices, setDevices] = useState([]);
  const [showDevice, setShowDevice] = useState<DeviceEntity | null>(null);
  useEffect(() => {
    async function loadDevices() {
      let rs = await api.post("/device/get-device-map", {});
      setDevices(rs.deviceInfos);
    }
    loadDevices();
  }, []);
  const defaultProps = {
    center: {
      lat: 21.050738,
      lng: 105.808725,
    },
    zoom: 14,
  };
  const renderMarkers = (map: any, maps: any) => {
    let marker = new maps.Marker({
      position: { lat: defaultProps.center.lat, lng: defaultProps.center.lng },
      map,
      title: "Hello World!",
    });
    return marker;
  };
  return (
    <div>
      <Modal onClose={() => setShowDevice(null)} open={showDevice !== null}>
        <div>
          <div className="bg-[#382372] text-white font-bold text-xl p-3">
            {showDevice?.name}
          </div>
          {showDevice && (
            <div className="flex flex-wrap p-4 text-black">
              <div className="w-1/2 mt-2">
                <p className="font-bold">Tên thiết bị</p>
                <p>{showDevice.name}</p>
              </div>
              <div className="w-1/2 mt-2">
                <p className="font-bold">Serial máy</p>
                <p className="mt-1">{showDevice.serial}</p>
              </div>
              <div className="w-1/2 mt-2">
                <p className="font-bold">Ngày cấp phát</p>
                <p className="mt-1">
                  {dayjs(showDevice.issue_date).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="w-1/2 mt-2">
                <p className="font-bold">Số quyết định trang cấp</p>
                <p className="mt-1">{showDevice.issue_paper}</p>
              </div>
              <div className="w-1/2 mt-2">
                <p className="font-bold">Ngày kiểm định</p>
                <p className="mt-1">
                  {dayjs(showDevice.check_date).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="w-1/2 mt-2">
                <p className="font-bold">Hạn kiểm định</p>
                <p className="mt-1">{showDevice.check_duration} tháng</p>
              </div>
              <div className="w-1/2 mt-2">
                <p className="font-bold">Ngày bắt đầu sử dụng</p>
                <p className="mt-1">
                  {dayjs(showDevice.start_use).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="w-1/2 mt-2">
                <p className="font-bold">Đơn vị quản lý</p>
                <p className="mt-1">{showDevice.manager_team?.name || "---"}</p>
              </div>
              <div className="w-1/2 mt-2">
                <p className="font-bold">Đơn vị sử dụng</p>
                <p className="mt-1">{showDevice.user_team?.name || "---"}</p>
              </div>
              <div className="w-full mt-2">
                <p className="font-bold">Cán bộ sử dụng</p>
                <p className="mt-1">{showDevice.user?.name || "---"}</p>
              </div>
              <div className="w-full mt-2">
                <p className="font-bold">Vị trí đo</p>
                <p className="mt-1">{showDevice.location}</p>
              </div>
              <div className="w-full mt-2">
                <p className="font-bold">Số lượt phát hiện vi phạm</p>
                <p className="mt-1">{showDevice.violate}</p>
              </div>
              <div className="w-full mt-2">
                <p className="font-bold">Số lượt dừng xử lý</p>
                <p className="mt-1">{showDevice.stopped | 0}</p>
              </div>
              <div className="w-full mt-2">
                <p className="font-bold">Số lượt gửi thông báo vi phạm</p>
                <p className="mt-1">{showDevice.violate_noti || 0}</p>
              </div>
            </div>
          )}
          <div className="w-full flex justify-end p-4">
            <Button
              icon="shutdown"
              content="Thoát"
              color="orange"
              onClick={() => {
                setShowDevice(null);
              }}
            />
          </div>
        </div>
      </Modal>
      <div style={{ height: "80vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBN1ywHUMsrHdJql5X-YV5jt8LlW8Z04mA" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
          {devices.map((d: any) => (
            <div
              onClick={() => {
                setShowDevice(d);
              }}
              className=" bg-white p-2"
              style={{ height: 80, width: 70 }}
              // @ts-ignore
              lat={d.lat}
              lng={d.lng}
            >
              {d.type === 0 && (
                <div>
                  <img
                    src="speed.png"
                    style={{ height: 50 }}
                    className="mx-auto block"
                  />
                  {d.is_online && (
                    <div className="font-bold text-green-700 flex gap-1 w-full">
                      <div className="h-4 w-4 rounded-full bg-green-700"></div>
                      <p>Online</p>
                    </div>
                  )}
                  {!d.is_online && (
                    <div className="font-bold text-red-700 flex gap-1 w-full">
                      <div className="h-4 w-4 rounded-full bg-red-700"></div>
                      <p>Offline</p>
                    </div>
                  )}
                </div>
              )}
              {d.type === 1 && (
                <div>
                  <img
                    src="alcohol.png"
                    style={{ height: 50 }}
                    className="mx-auto block"
                  />
                  {d.is_online && (
                    <div className="font-bold text-green-700 flex gap-1 w-full">
                      <div className="h-4 w-4 rounded-full bg-green-700"></div>
                      <p>Online</p>
                    </div>
                  )}
                  {!d.is_online && (
                    <div className="font-bold text-red-700 flex gap-1 w-full">
                      <div className="h-4 w-4 rounded-full bg-red-700"></div>
                      <p>Offline</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </GoogleMapReact>
      </div>
    </div>
  );
}
