let uid = 0;
function matchText(search: string, text: string) {
  if (!(search && text)) return false;
  return text.toLowerCase().indexOf(search.toLowerCase()) >= 0;
}
function getUid() {
  return uid++;
}
function convertValue(val: any, data: any) {
  if (!val) return val;
  if (typeof val === "number") {
    return val;
  }
  if (val[0] === "$") {
    const key: string = val.substr(1, val.length - 1);
    //@ts-ignore
    return data[key];
  }
  return val;
}
function checkExpression(express: any, data: any) {
  for (var i in express) {
    let e = express[i];
    switch (String(i)) {
      case "$eq":
        if (convertValue(e[0], data) !== convertValue(e[1], data)) {
          return false;
        }
        break;
      case "$in":
        if (
          !(
            convertValue(e[0], data) in
            e[1].map((i: any) => convertValue(i, data))
          )
        ) {
          return false;
        }
        break;
      case "$nin":
        if (
          convertValue(e[0], data) in
          e[1].map((i: any) => convertValue(i, data))
        ) {
          return false;
        }
        break;
      case "$neq":
        if (convertValue(e[0], data) === convertValue(e[1], data)) {
          return false;
        }
        break;

      default:
        return false;
    }
  }
  return true;
}
const utils = { matchText, getUid, checkExpression };
export default utils;
