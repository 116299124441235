import { DatePicker, GridView, Loading, ViewEnum } from "components";
import dayjs from "dayjs";
import _ from "lodash";
import { forwardRef, useEffect, useRef, useState } from "react";
import Lightbox from "react-image-lightbox";
import { Button, Card, Form, Modal, Select, Table } from "semantic-ui-react";
import { api, ui } from "services";
import ReactToPrint from "react-to-print";
import { ViolateEntity } from "interfaces";
import { useLocation, useParams } from "react-router-dom";
export default function ApproveAlcoholViolate() {
  const { status } = useParams();
  const [input, setInput] = useState<any>({});
  const [devices, setDevices] = useState<any[]>([]);
  const [report, setReport] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [deviceInfos, setDeviceInfos] = useState<any[]>([]);
  const [teamInfos, setTeamInfos] = useState<any[]>([]);
  const [violateIds, setViolateIds] = useState<number[]>([]);
  const [violates, setViolates] = useState<ViolateEntity[]>([]);
  const [viewImage, setViewImage] = useState<string>(null);
  const [viewVehicle, setViewVehicle] = useState<boolean>(false);
  const detailRef = useRef();
  function handleInput(name: string, value: any) {
    let tmp = _.cloneDeep(input);
    tmp[name] = value;
    setInput(tmp);
  }
  useEffect(() => {
    setViolateIds([]);
  }, [status]);
  async function loadViolateInfo() {
    if (!violateIds.length) {
      return setViolates([]);
    }
    let rs = await api.post("/api/get-violate", { where: { id: violateIds } });
    setViolates(rs.data);
  }
  useEffect(() => {
    if (violateIds) {
      loadViolateInfo();
    }
  }, [violateIds]);
  async function approveViolate() {
    try {
      await ui.confirm("Bạn có chắc chắn muốn xác nhận vi phạm không?");
      await api.post("/violate/change-violate-status", {
        ids: violates.map((i) => i.id),
        status: 1,
      });
      await loadViolateInfo();
      ui.alert("Xác nhận vi phạm thành công");
    } catch (error) {}
  }
  async function ignoreViolate() {
    try {
      await ui.confirm("Xác nhận Không đủ căn cứ xử phạt?");
      await api.post("/violate/change-violate-status", {
        ids: violates.map((i) => i.id),
        status: 3,
      });
      await loadViolateInfo();
      ui.alert("Thành công");
    } catch (error) {}
  }
  async function sendNotiViolate() {
    try {
      await ui.confirm("Xác nhận Gửi thông báo vi phạm?");
      await api.post("/violate/change-violate-status", {
        ids: violates.map((i) => i.id),
        status: 2,
      });
      await loadViolateInfo();
      ui.alert("Thành công");
    } catch (error) {}
  }
  useEffect(() => {
    async function loadData() {
      let devices = await api.post("/api/get-devices", {});
      setDevices(devices.data);
    }
    loadData();
  }, []);
  function list() {
    return (
      <div>
        <GridView
          gridName="alcohol-violate"
          canSelect
          embedFilter={{ status: status }}
          selectedItems={violates}
          onItemSelected={(item: any) => {
            let tmp: number[] = [];
            if (violateIds.includes(item.id)) {
              tmp = violateIds.filter((i: number) => i !== item.id);
            } else {
              tmp = _.cloneDeep(violateIds);
              tmp.push(item.id);
            }
            setViolateIds(tmp);
          }}
        />
      </div>
    );
  }
  const fields = [
    {
      title: "Biển số xe",
      field: "plate",
    },
    {
      title: "Chủ sở hữu",
      field: "owner",
    },

    {
      title: "Địa chỉ",
      field: "address",
    },

    {
      title: "Số điện thoại",
      field: "phone",
    },

    {
      title: "Nhãn hiệu",
      field: "manufacture",
    },

    {
      title: "Loại xe",
      field: "vehicle_type",
    },
    {
      title: "Số loại",
      field: "type_number",
    },
    {
      title: "Màu xe",
      field: "color",
    },

    {
      title: "Màu biển số",
      field: "plate_color",
    },

    {
      title: "Tải trọng",
      field: "weight",
    },

    {
      title: "Số chỗ ngồi",
      field: "seat",
    },

    {
      title: "Số khung",
      field: "frame_number",
    },

    {
      title: "Số máy",
      field: "engine_number",
    },

    {
      title: "Dung tích",
      field: "capacity",
    },

    {
      title: "Số chứng nhận đăng ký",
      field: "register_number",
    },

    {
      title: "Niên hạn",
      date: true,
      field: "expire_date",
    },

    {
      title: "Ngày đăng ký lần đầu",
      date: true,
      field: "register_first_date",
    },

    {
      title: "Ngày đăng ký",
      date: true,
      field: "register_date",
    },

    {
      title: "NGày đăng ký có giá trị đến",
      date: true,
      field: "register_expire",
    },
  ];
  return (
    <div>
      <Modal
        open={viewVehicle}
        onClose={() => setViewVehicle(false)}
        size="large"
      >
        <Modal.Header>DỮ LIỆU ĐĂNG KÝ XE</Modal.Header>
        <Modal.Content>
          {violates.length && (
            <div className="flex flex-wrap">
              {fields.map((f: any, index: number) => (
                <div className="w-1/3 mt-4">
                  <p className="font-bold">{f.title}</p>
                  {f.date ? (
                    <p className="font-bold text-blue-800">
                      {/* @ts-ignore */}
                      {violates[0][f.field]
                        ? // @ts-ignore
                          dayjs(violates[0][f.field]).format("DD/MM/YYYY")
                        : "---"}
                    </p>
                  ) : (
                    <p className="font-bold text-blue-800">
                      {/* @ts-ignore */}
                      {violates[0][f.field] || "---"}
                    </p>
                  )}
                </div>
              ))}
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <div className="flex justify-end">
            <Button
              icon="close"
              content="Thoát"
              color="orange"
              onClick={() => {
                setViewVehicle(false);
              }}
            />
          </div>
        </Modal.Actions>
      </Modal>
      {viewImage && (
        <Lightbox
          mainSrc={viewImage}
          onCloseRequest={() => {
            setViewImage("");
          }}
        />
      )}
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <div className="flex justify-between">
              <p>Kiểm duyệt vi phạm</p>
            </div>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <div className="flex gap-2 w-full">
            <div className="w-1/2">{list()}</div>
            <div className="w-1/2">
              {
                <Detail
                  ref={detailRef}
                  violate={violates[0]}
                  setViewImage={setViewImage}
                  setViewVehicle={setViewVehicle}
                />
              }
              {violates.length > 0 && (
                <div className="flex justify-end">
                  {violates[0].status === 0 && (
                    <Button
                      icon="pencil"
                      content="Xác nhận vi phạm"
                      color="blue"
                      onClick={approveViolate}
                    />
                  )}
                  {violates[0].status === 0 && (
                    <Button
                      icon="pencil"
                      content="Không đủ căn cứ xử phạt"
                      color="red"
                      onClick={ignoreViolate}
                    />
                  )}
                  {violates[0].status === 1 && (
                    <Button
                      icon="pencil"
                      content="Gửi thông báo vi phạm"
                      color="red"
                      onClick={sendNotiViolate}
                    />
                  )}
                  <Button
                    icon="pencil"
                    content="Sửa"
                    color="orange"
                    as="a"
                    href={`#/form/violate/edit?id=${violates[0]?.id}`}
                  />
                  <ReactToPrint
                    trigger={() => (
                      <Button icon="print" content="In vi phạm" color="teal" />
                    )}
                    content={() => detailRef?.current}
                  />
                </div>
              )}
            </div>
          </div>
        </Card.Content>
      </Card>
    </div>
  );
}
const Detail = forwardRef((props: any, ref: any) => {
  const { violate, setViewImage, setViewVehicle } = props;
  if (!violate) {
    return null;
  }
  return (
    <Card fluid ref={ref}>
      <Card.Content>
        <Card.Header>
          <div className="flex justify-between">
            <p>Thông tin vi phạm</p>
          </div>
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <img
          src={violate.image}
          className="w-[200px] cursor-pointer"
          onClick={() => {
            setViewImage(violate.image);
          }}
        />
        <div className="flex flex-wrap">
          <div className="w-full mt-2 flex gap-2 items-center">
            <p className="font-bold">Tình trạng vi phạm</p>
            <ViewEnum enumName="violate_status" value={violate.status} />
          </div>
          <div className="w-1/2 mt-2">
            <p className="font-bold">Biển số xe vi phạm</p>
            <p>
              <span
                className="mt-1 p-2 bg-yellow-400 border rounded-sm font-bold cursor-pointer"
                onClick={() => {
                  setViewVehicle(true);
                }}
              >
                {violate.plate}
              </span>
            </p>
          </div>
          <div className="w-1/2 mt-2">
            <p className="font-bold">Loại phương tiện</p>
            <p className="mt-1">{violate.vehicle_name}</p>
          </div>
          <div className="w-1/2 mt-2">
            <p className="font-bold">Chủ sở hữu</p>
            <p className="mt-1">{violate.owner}</p>
          </div>
          <div className="w-1/2 mt-2">
            <p className="font-bold">Địa chỉ</p>
            <p className="mt-1">{violate.address}</p>
          </div>
          <div className="w-1/2 mt-2">
            <p className="font-bold">Màu xe</p>
            <p className="mt-1">{violate.color}</p>
          </div>
          <div className="w-1/2 mt-2">
            <p className="font-bold">Nhãn hiệu xe</p>
            <p className="mt-1">{violate.manufacture}</p>
          </div>
          <div className="w-1/2 mt-2">
            <p className="font-bold">Số loại</p>
            <p className="mt-1">{violate.code}</p>
          </div>
          <div className="w-1/2 mt-2">
            <p className="font-bold">Số chỗ ngồi</p>
            <p className="mt-1">{violate.seat}</p>
          </div>
          <div className="w-1/2 mt-2">
            <p className="font-bold">Màu biển số</p>
            <p className="mt-1">{violate.plate_color}</p>
          </div>
          <div className="w-full mt-2">
            <p className="font-bold">Hành vi vi phạm</p>
            <p className="mt-1">{violate.violate}</p>
          </div>
          <div className="w-full mt-2">
            <p className="font-bold">Căn cứ xử phạt</p>
            <p className="mt-1">{violate.base}</p>
          </div>
          <div className="w-full mt-2">
            <p className="font-bold">Địa điểm vi phạm</p>
            <p className="mt-1">{violate.location}</p>
          </div>
          <div className="w-full mt-2">
            <p className="font-bold">Thời gian vi phạm</p>
            <p className="mt-1">
              {dayjs(violate.create_date).format("DD/MM/YYYY HH:mm")}
            </p>
          </div>
          <div className="w-full mt-2">
            <p className="font-bold">Thiết bị nghiệp vụ phát hiện vi phạm</p>
            <p className="mt-1">{violate.device.name}</p>
          </div>
        </div>
      </Card.Content>
    </Card>
  );
});
